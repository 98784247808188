.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: 1fr;
    grid-auto-rows: 1;
    grid-gap:12 px;
    grid-row-gap: 12px;
    width: 75%;
}

.item{
    min-height: 200px;
}